<template>
    <div class="toning-setting__with-colors">
        <div class="toning-setting__color-lable">
            <p>{{label}}</p>
            <p class="toning-setting__tile-note" v-if="note">{{note}}</p>
        </div>
        <div class="form-group-picker">
            <select v-model="value" @change="changed" v-if="selections.length > 0">
                <option :value="option.value" v-for="(option,key) in selections" :key="key">{{option.text}}</option>
            </select>
            <b-form-checkbox :value="1"
                             :unchecked-value="0"
                             class="form-group-picker__toggle"
                             v-model="value"
                             name="check-button"
                             @change="changed"
                             switch
                             v-else-if="inputType === 'toggle'" size="lg"></b-form-checkbox>
            <information-button :image="informationImage"></information-button>
        </div>
    </div>
</template>

<script>
  import InformationButton from './InformationButton'

  export default {
    name: 'OtherTile',
    props: ['data', 'component'],
    components: {
      InformationButton
    },
    data () {
      return {
        selections: [],
        label: '',
        value: '',
        field: '',
        note: '',
        informationImage: '',
        inputType: 'select'
      }
    },
    mounted () {
      this.label = this.data.label
      this.value = 'data' in this.data ? this.data.data.value : this.data.config.default
      this.field = this.data.field
      this.note = this.data.config.note
      this.informationImage = 'instructions' in this.data.config ? this.data.config.instructions : ''
      this.selections = 'selections' in this.data.config ? this.data.config.selections : []
      this.inputType = this.data.config.input
    },
    methods: {
      changed () {
        this.$emit('changed', this.data.field, this.value, this.component)
      }
    }
  }
</script>
