<template>
  <div class="feature-body-flag">
    <div class="checkbox switcher mb-2">
      <label>
          <span class="feature-body-flag__type-selection-item ml-2">Show manual input :</span>
          <input type="checkbox" v-model="bodyFatRanges.showInput" checked @change="change">
          <span><small></small></span>
      </label>
    </div>
    <div class="feature-body-flag__type-selection">
      <div class="feature-body-flag__type-selection-item">Display label as :</div>

      <!-- ------------------------------------ -->

      <div class="custom-control custom-radio custom-control-inline">
        <input
          class="custom-control-input"
          id="customRadio1"
          name="example1"
          type="radio"
          value="showFat"
          v-model="selectedOption"
        />
        <label class="custom-control-label" for="customRadio1"
          >Fat % Range</label
        >
      </div>
      <div class="custom-control custom-radio custom-control-inline">
        <input
          type="radio"
          class="custom-control-input"
          id="customRadio2"
          name="example2"
          value="showTextLable"
          v-model="selectedOption"
        />
        <label class="custom-control-label" for="customRadio2"
          >Text labels</label
        >
      </div>

      <!-- -------------------------------------- -->

      <!-- <div class="col-md-2 col-4">
        <div class="form-check custom-radio">
          <input
            class="form-check-input"
            type="radio"
            value="showFat"
            v-model="selectedOption"
          />
          <label class="form-check-label" for="option1">Fat % Range</label>
        </div>
      </div>
      <div class="col-md-2 col-4">
        <div class="form-check custom-radio">
          <input
            class="form-check-input"
            type="radio"
            value="showTextLable"
            v-model="selectedOption"
          />
          <label class="form-check-label" for="option2">Text labels</label>
        </div>
      </div> -->
    </div>
    <div class="container">
      <div class="mt-4">
        <div class="feature-body-flag__second-nav">
          <b-tabs
            class="feature-body-flag__gender-tabs"
            v-model="selectedGender"
            nav-class="feature-flag-nav mb-4"
          >
            <b-tab
              class="feature-body-flag__second-nav-left-side"
              title="Male"
              active
            ></b-tab>
            <b-tab
              class="feature-body-flag__second-nav-right-side"
              title="Female"
            ></b-tab>
          </b-tabs>
        </div>
      </div>
      <div class="row feature-body-flag__list-title">
        <div class="col-2">
          <small><strong>image*</strong> <span>(560 x 560)</span></small>
        </div>
        <div class="col-3">
          <small><strong>Fat Range (%)*</strong></small>
        </div>
        <div class="col-5">
          <small><strong>Text label</strong></small>
        </div>
        <div class="col-2"></div>
      </div>
      <div
        class="row feature-body-flag__item"
        v-for="(item, index) in selectedArray"
        :key="index"
      >
        <div class="col-2">
          <div class="feature-body-flag__item-image">
            <img
              :src="item.image"
              alt="Image"
              class="feature-body-flag__preview-img"
            />
            <span
              v-if="isupdateLoading && updateIndex === index"
              class="spinner-border spinner-border-sm feature-body-flag__edit-icon"
              role="status"
              aria-hidden="true"
            ></span>
            <font-awesome-icon
              v-else
              @click="handleUploadClickForUpdate(index)"
              :icon="['fas', 'edit']"
              size="sm"
              class="feature-body-flag__edit-icon"
            />
          </div>
        </div>
        <div class="col-3">
          <div class="feature-body-flag__group-inputs-wrapper">
            <b-form-input
              v-model="item.min"
              placeholder="Min %"
              class="mr-2"
              @blur="checkCurrentMinWithPreviousValue(index)"
              @focus="isWarningTriggered = false"
            ></b-form-input>
            <div class="mt-2">-</div>
            <b-form-input
              v-model="item.max"
              placeholder="Max %"
              class="ml-2"
              @blur="addNewValueToStartNextData(index)"
              @focus="isWarningTriggered = false"
            ></b-form-input>
          </div>
        </div>
        <div class="feature-body-flag__text-lable-input col-5">
          <b-form-input
            v-model="item.label"
            placeholder="Add text lable"
            :disabled="selectedOption !== 'showTextLable'"
            @focus="isWarningTriggered = false"
          ></b-form-input>
        </div>
        <div class="col-2">
          <font-awesome-icon
            class="mt-2"
            :icon="['fas', 'minus-circle']"
            size="lg"
            @click="removeMatric(index)"
          />
        </div>
        <div class="error-message" v-if="validationMessages[index]">
          {{ validationMessages[index] }}
        </div>
      </div>
      <div
        v-if="selectedArray.length < 10"
        class="row mt-4 feature-body-flag-bottom-bar"
      >
        <div class="col-2">
          <div v-if="newImage" class="feature-body-flag__item-image">
            <img
              :src="newImage"
              alt="Image"
              class="feature-body-flag__preview-img"
            />
            <span
              v-if="isNewUploadLoading"
              class="spinner-border spinner-border-sm feature-body-flag__edit-icon"
              role="status"
              aria-hidden="true"
            ></span>
            <font-awesome-icon
              v-else
              @click="handleUploadClick"
              :icon="['fas', 'edit']"
              size="sm"
              class="feature-body-flag__edit-icon"
            />
          </div>
          <b-button
            v-else
            class="feature-body-flag__upload-btn"
            @click="handleUploadClick"
            variant="primary"
          >
            <span
              v-if="isNewUploadLoading"
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span v-if="!isNewUploadLoading" class="d-none d-lg-inline"
              >Upload</span
            >

            <font-awesome-icon
              v-if="!isNewUploadLoading"
              class="ml-1"
              :icon="['fas', 'upload']"
              size="xs"
            />
          </b-button>

          <input
            ref="fileInput"
            type="file"
            class="d-none"
            @change="handleFileChange"
          />
          <input
            ref="fileUpdateInput"
            type="file"
            class="d-none"
            @change="handleFileUpdateChange"
          />
        </div>
        <div class="col-3">
          <div class="feature-body-flag__group-inputs-wrapper">
            <b-form-input
              v-model="rangeMin"
              placeholder="Min %"
              class="mr-2"
              @focus="isWarningTriggered = false"
            ></b-form-input>
            <div class="mt-2">-</div>
            <b-form-input
              v-model="rangeMax"
              placeholder="Max %"
              class="ml-2"
              @focus="isWarningTriggered = false"
            ></b-form-input>
          </div>
        </div>
        <div class="col-5">
          <b-form-input
            v-model="newTextLabel"
            placeholder="Add text lable"
            :disabled="selectedOption !== 'showTextLable'"
            @focus="isWarningTriggered = false"
          ></b-form-input>
        </div>
        <div class="col-2">
          <b-button variant="primary" @click="AddMatric()">+ Add</b-button>
        </div>
        <span class="recommended-image-size-text"
          >Recommended image resolution 560 x 560 and maximum size 1MB.
        </span>
        <div v-if="newInputValidationMessage" class="error-message">
          {{ newInputValidationMessage }}
        </div>
      </div>

      <div class="row">
        <div v-if="isWarningTriggered" class="warning-message mt-2">
          {{ warningMessage }}
        </div>
        <div
          v-if="isWarningTriggered"
          class="col-12 feature-body-flag-bottom-bar-save-btn"
        >
          <b-button variant="primary" @click="save()"
            >Understand and Continue</b-button
          >
        </div>
        <div v-else class="col-12 feature-body-flag-bottom-bar-save-btn">
          <b-button variant="primary" @click="save()">Save Changes</b-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadService from "../../services/api/trainer/UploadService";
import NotificationService from "../../services/NotificationService";
import TTInstanceService from "../../services/TTInstanceService";
export default {
  data() {
    return {
      item: {
        min: "",
        max: "",
      },
      updateIndex: null,
      isupdateLoading: false,
      isNewUploadLoading: false,
      newTextLabel: "",
      newImage: "",
      rangeMin: "",
      rangeMax: "",
      selectedOption: "showFat",
      isShowNewInputs: false,
      selectedGender: 0,
      selectedIndexForEdit: null,
      bodyFatRanges: {
        showInput:  true,
        isTextLabel: false,
        m: [
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/men/m1.jpg",
            min: "3",
            max: "5",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/men/m2.jpg",
            min: "6",
            max: "8",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/men/m3.jpg",
            min: "9",
            max: "13",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/men/m4.jpg",
            min: "14",
            max: "17",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/men/m5.jpg",
            min: "18",
            max: "22",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/men/m6.jpg",
            min: "23",
            max: "27",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/men/m7.jpg",
            min: "28",
            max: "32",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/men/m8.jpg",
            min: "33",
            max: "37",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/men/m9.jpg",
            min: "38",
            max: "50",
          },
        ],
        f: [
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/women/f1.jpg",
            min: "10",
            max: "13",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/women/f2.jpg",
            min: "14",
            max: "18",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/women/f3.jpg",
            min: "19",
            max: "23",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/women/f4.jpg",
            min: "24",
            max: "27",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/women/f5.jpg",
            min: "28",
            max: "32",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/women/f6.jpg",
            min: "33",
            max: "37",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/women/f7.jpg",
            min: "38",
            max: "42",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/women/f8.jpg",
            min: "43",
            max: "47",
          },
          {
            image:
              "https://d39bs4ovl1ajzi.cloudfront.net/custom-body-fat-images/common/women/f9.jpg",
            min: "48",
            max: "50",
          },
        ],
      },
      validationMessages: [],
      newInputValidationMessage: "",
      isWarningTriggered: false,
      warningMessage: "",
    };
  },
  mounted() {
    this.loadBodyFatValues();
    this.validateMinMax();
  },
  computed: {
    selectedArray() {
      return this.selectedGender === 0
        ? this.bodyFatRanges.m
        : this.bodyFatRanges.f;
    },
    lastMaxValue() {
      const lastItem = this.selectedArray[this.selectedArray.length - 1];
      return parseFloat(lastItem.max) || 0;
    },
    isRangeValid() {
      return parseFloat(this.rangeMin) > this.lastMaxValue;
    },
  },
  methods: {
    addNewValueToStartNextData(index) {
      let nextObj = this.selectedArray[index + 1];

      if (nextObj !== undefined) {
        nextObj.min = (
          parseFloat(this.selectedArray[index].max) + 1
        ).toString();
      }

      this.validateMinMax();
    },
    checkCurrentMinWithPreviousValue(index) {
      let preObjMax = parseFloat(this.selectedArray[index - 1].max);
      let currentObjMin = parseFloat(this.selectedArray[index].min);

      console.log(parseFloat(this.selectedArray[index - 1]));
      console.log(parseFloat(this.selectedArray[index]));
      if (currentObjMin - preObjMax > 1) {
        this.$set(
          this.validationMessages,
          index,
          `Min % must be start with ${preObjMax + 1}`
        );

        return;
      }
      this.validateMinMax();
    },
    validateMinMax() {
      this.validationMessages = [];

      this.selectedArray.forEach((item, index) => {
        const { min, max } = item;

        if (min !== "" && max !== "") {
          const currentMin = parseFloat(min);
          const currentMax = parseFloat(max);

          if (currentMin > 50 || currentMax > 50) {
            this.$set(
              this.validationMessages,
              index,
              "Min % and Max % values cannot exceed 50."
            );
            return;
          }

          if (currentMin >= currentMax) {
            this.$set(
              this.validationMessages,
              index,
              "Min % must be less than Max %."
            );
          } else {
            if (index > 0) {
              const previousMax = parseFloat(this.selectedArray[index - 1].max);
              if (currentMin <= previousMax) {
                this.$set(
                  this.validationMessages,
                  index,
                  "Min % must be greater than the previous Max %."
                );
              }
            }

            if (index < this.selectedArray.length - 1) {
              const nextMin = parseFloat(this.selectedArray[index + 1].min);
              if (currentMax >= nextMin) {
                this.$set(
                  this.validationMessages,
                  index,
                  "Max % must be less than the next Min %."
                );
              }
            }
          }
        }
      });
    },
    loadBodyFatValues() {
      const desiredItem = this.$store.getters.getInstance.feature_flags.find(
        (item) =>
          item.feature_flag &&
          item.feature_flag.config_key === "custom_body_fat_image_config"
      );

      const desiredValue = desiredItem ? desiredItem.value : null;

      if (desiredValue instanceof Object) {
        this.bodyFatRanges = desiredValue;
      } else if (typeof desiredValue === "string") {
        this.bodyFatRanges = JSON.parse(desiredValue);
      }

      if (typeof this.bodyFatRanges.isTextLabel !== "undefined") {
        this.selectedOption = this.bodyFatRanges.isTextLabel
          ? "showTextLable"
          : "showFat";
      } else {
        this.selectedOption = "showFat";
      }
    },
    availableWarnings() {
      for (const obj of this.bodyFatRanges.m) {
        if (!("label" in obj) || obj.label === "") {
          return true;
        }
      }

      for (const obj of this.bodyFatRanges.f) {
        if (!("label" in obj) || obj.label === "") {
          return true;
        }
      }

      return false;
    },
    async save() {
      if (this.validationMessages.length > 0) {
        return;
      }

      if (this.selectedArray.length < 3) {
        new NotificationService().errorNotification(
          "A Minimum of 3 options should be present for each gender"
        );
        return;
      }

      if (this.selectedOption === "showTextLable") {
        if (this.availableWarnings() && !this.isWarningTriggered) {
          this.isWarningTriggered = true;
          this.warningMessage =
            "There are empty labels. Please check both Male and Female Tabs. OR can continue!";
          return;
        }
      }

      try {
        this.$store.commit("setLoading", true);

        this.selectedOption === "showFat"
          ? (this.bodyFatRanges.isTextLabel = false)
          : (this.bodyFatRanges.isTextLabel = true);

        const tTInstanceService = new TTInstanceService();
        await tTInstanceService.setConfig(
          this.$store.getters.getInstance.id,
          "custom_body_fat_image_config",
          JSON.stringify(this.bodyFatRanges)
        );

        new NotificationService().successNotification("Successfully Added");
        this.isWarningTriggered = false;
        this.warningMessage = "";
      } catch (error) {
        new NotificationService().errorNotification("Somthing went wrong");
      } finally {
        this.$store.commit("setLoading", false);
      }
    },
    handleUploadClick() {
      this.$refs.fileInput.click();
    },
    handleUploadClickForUpdate(index) {
      this.updateIndex = index;
      this.$refs.fileUpdateInput.click();
      this.selectedIndexForEdit = index;
    },
    async handleFileUpdateChange(event) {
      try {
        this.isupdateLoading = true;
        const file = event.target.files[0];

        if (file) {
          const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

          if (!allowedTypes.includes(file.type)) {
            new NotificationService().errorNotification(
              "Only PNG and JPEG files are allowed."
            );
            return;
          }

          const fileSizeLimit = 2 * 1024 * 1024;

          if (file.size > fileSizeLimit) {
            new NotificationService().errorNotification(
              "File size exceeds the 2MB limit."
            );
            return;
          }

          const payload = {
            instance_id: this.$store.getters.getInstance.id,
            type: "body_fat",
          };
          const uploadService = new UploadService();
          const response = await uploadService.upload(payload, file);

          if (this.selectedGender === 0) {
            this.bodyFatRanges.m[this.selectedIndexForEdit].image = response;
          } else {
            this.bodyFatRanges.f[this.selectedIndexForEdit].image = response;
          }
        }
      } catch (error) {
        new NotificationService().errorNotification("Somthing went wrong");
      } finally {
        this.isupdateLoading = false;
        this.updateIndex = null;
      }
    },
    async handleFileChange(event) {
      try {
        this.isNewUploadLoading = true;

        const file = event.target.files[0];
        if (file) {
          const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

          if (!allowedTypes.includes(file.type)) {
            new NotificationService().errorNotification(
              "Only PNG and JPEG files are allowed."
            );
            return;
          }

          const fileSizeLimit = 1 * 1024 * 1024;

          if (file.size > fileSizeLimit) {
            new NotificationService().errorNotification(
              "File size exceeds the 1MB limit."
            );
            return;
          }

          const payload = {
            instance_id: this.$store.getters.getInstance.id,
            type: "body_fat",
          };
          const uploadService = new UploadService();
          const response = await uploadService.upload(payload, file);

          this.newImage = response;
        }

        new NotificationService().successNotification("Successfully Uploaded");
      } catch (error) {
        new NotificationService().errorNotification("Somthing went wrong");
      } finally {
        this.isNewUploadLoading = false;
      }
    },

    AddMatric() {
      this.newInputValidationMessage = "";

      if (!this.newImage) {
        new NotificationService().errorNotification(
          "Adding image is mandatory for each option."
        );
        return;
      }

      if (!this.rangeMax || !this.rangeMin) {
        this.newInputValidationMessage =
          "Adding Min and Max values are mandatory for each option.";
        return;
      }

      if (parseFloat(this.rangeMin) > 50 || parseFloat(this.rangeMax) > 50) {
        this.newInputValidationMessage =
          "Min % and Max % values cannot exceed 50.";
        return;
      }

      if (
        parseFloat(this.rangeMin) !==
        parseFloat(this.selectedArray[this.selectedArray.length - 1].max) + 1
      ) {
        this.newInputValidationMessage = `Min Value must be start with ${
          parseFloat(this.selectedArray[this.selectedArray.length - 1].max) + 1
        }`;
        return;
      }

      if (!this.isRangeValid) {
        this.newInputValidationMessage =
          "Min % must be greater than the last Max % in selected range.";
        return;
      }

      if (this.selectedArray.length >= 10) {
        new NotificationService().errorNotification(
          "Maximum 10 options can be present for each gender"
        );
        return;
      }
      if (
        this.selectedOption === "showFat" &&
        this.rangeMin &&
        this.rangeMax &&
        this.newImage
      ) {
        let obj = {
          image: this.newImage,
          min: this.rangeMin,
          max: this.rangeMax,
        };

        if (this.selectedGender === 0) {
          this.bodyFatRanges.m.push(obj);
        } else {
          this.bodyFatRanges.f.push(obj);
        }

        (this.newImage = ""), (this.rangeMin = ""), (this.rangeMax = "");
      }

      if (
        this.selectedOption === "showTextLable" &&
        this.rangeMin &&
        this.rangeMax &&
        this.newImage
      ) {
        let obj = {
          image: this.newImage,
          min: this.rangeMin,
          max: this.rangeMax,
          label: this.newTextLabel,
        };

        if (this.selectedGender === 0) {
          this.bodyFatRanges.m.push(obj);
        } else {
          this.bodyFatRanges.f.push(obj);
        }

        (this.newImage = ""), (this.newTextLabel = "");
      }
    },
    removeMatric(index) {
      if (this.selectedGender === 0) {
        this.bodyFatRanges.m.splice(index, 1);
      } else {
        this.bodyFatRanges.f.splice(index, 1);
      }
    },

    getImageName(url) {
      const parts = url.split("/");
      return parts.pop();
    },
  },
};
</script>
