<template>
    <div>
        <component-selector></component-selector>
        <component-type-tabs @componentSelectionTypeChanged="selectionTypeChange"></component-type-tabs>
        <common-properties v-if="componentPropertyTab == 'common'" @submitPropData="submitPropData"
                           @showUpload="showUpload" @showResetConfirm="showResetConfirm"></common-properties>
        <component-properties v-else @submitPropData="submitPropData" @showUpload="showUpload"
                              @showResetConfirm="showResetConfirm"></component-properties>
        <input type="file" ref="fileInut" style="display: none" @change="handleUploadFile"/>
        <popup-prop-reset-confirm></popup-prop-reset-confirm>
    </div>
</template>

<script>
  import ComponentSelector from './ComponentSelector'
  import ComponentTypeTabs from './ComponentTypeTabs'
  import TrainerService from '../../services/TrainerService'
  import ComponentProperties from './ComponentProperties'
  import CommonProperties from './CommonProperties'
  import NotificationService from '../../services/NotificationService'
  import TTInstanceService from '../../services/TTInstanceService'
  import PopupPropResetConfirm from './PropResetConfirmPopup'

  export default {
    name: 'ComponentPropertyManager',
    data () {
      return {
        trainerService: new TrainerService(),
        notificationService: new NotificationService(),
        instanceService: new TTInstanceService(),
        componentPropertyTab: '',
        toBeUpdateCompId: ''
      }
    },
    components: {
      PopupPropResetConfirm,
      ComponentSelector,
      ComponentTypeTabs,
      ComponentProperties,
      CommonProperties
    },
    computed: {
      component () {
        return this.$store.getters.getComponent
      },
      trainer () {
        return this.$store.getters.getTrainer
      }
    },
    watch: {},
    methods: {
      selectionTypeChange (selectionType) {
        this.componentPropertyTab = selectionType
      },
      submitPropData (field, data, componentId) {
        this.toBeUpdateCompId = componentId
        this.$store.commit('setLoading', true)
        this.submitTrainerComponentData(field, data).then(() => {
          this.$store.commit('setPreviewCssInjectRequired', true)
          this.notificationService.successNotification('Property updated successfully!')
        }).finally(() => {
          this.$store.commit('setLoading', false)
        })
      },
      submitTrainerComponentData (field, data) {
        return this.instanceService.submitComponentData(this.$store.getters.getInstance.id, this.toBeUpdateCompId, field, data)
      },
      showUpload (field, componentId) {
        this.toBeUpdateCompId = componentId
        this.$refs.fileInut.value = null
        this.selectedPropertyField = field
        this.$refs.fileInut.click()
      },
      showResetConfirm (field, componentId) {
        this.$store.commit('propResetPopup/setComponentId', componentId)
        this.$store.commit('propResetPopup/setField', field)
        this.$store.commit('propResetPopup/setShowStatus', true)
      },
      handleUploadFile (event) {
        let componentProperty = this.$store.getters['components/getPropertyByField'](this.selectedPropertyField)
        let configWidth = componentProperty.config ? componentProperty.config.width : null
        let configHeight = componentProperty.config ? componentProperty.config.height : null

        if (configWidth && configHeight) {
          let file = event.target.files[0]
          let image = new Image()
          image.src = window.URL.createObjectURL(file)

          image.onload = () => {
            let imgWidth = image.width
            let imgHeight = image.height

            if (imgWidth === configWidth && imgHeight === configHeight) {
              // in KB
              let fileSize = Math.round((file.size / 1024))

              // limit added as 1.5MB with buffer amount
              if (fileSize > 1600) {
                this.notificationService.errorNotification('Max image size should be 1.5MB')
              } else {
                // upload image
                this.$store.commit('setLoading', true)
                this.submitTrainerComponentData(this.selectedPropertyField, file).then(() => {
                  this.$store.commit('setPreviewRefreshRequired', true)
                  this.$store.commit('setComponentPropRefreshRequired', true)
                  this.notificationService.successNotification('Image Uploaded successfully!')
                }).finally(() => {
                  this.$store.commit('setLoading', false)
                })
              }
            } else {
              // invalid dimensions
              this.notificationService.errorNotification('Invalid dimensions provided. (Dimensions: ' + configWidth + 'x' + configHeight + ')')
            }
          }

          // if image could not be loaded
          image.onerror = () => {
            this.notificationService.errorNotification('Invalid image provided or type not supported. Supported formats: jpg, png, bmp, giff')
          }
        } else {
          this.notificationService.errorNotification('Something went wrong. Please try again shortly')
        }
      }
    }
  }
</script>

<style scoped>

</style>
