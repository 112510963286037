<template>
    <div v-if="instance && instance.id">
        <div v-if="previewRoutes.length > 0" class="text-white text-center toning-remark toning-remark--preview-scroll">
            Scroll the preview area
            to check all the page elements
        </div>

        <div v-if="previewRoutes.length == 0" class="text-white text-center toning-remark  toning-remark--no-preview">
            No preview available
        </div>
        <div class="btn-view-wrap">
            <div class="btn-group btn-group-toggle toning-view__toggle" data-toggle="buttons">

                <label :class="'btn btn-secondary '  + (previewType == 'mobile' ? 'active' : '')">
                    <img src="../assets/images/icon-mobile.svg" alt="">
                    <input type="radio" name="options" value="mobile" autocomplete="off" v-model="previewType"> Mobile
                </label>
                <label :class="'btn btn-secondary ' + (previewType == 'tab' ? 'active' : '')">
                    <img src="../assets/images/icon-tab.svg" alt="">
                    <input type="radio" name="options" value="tab" autocomplete="off" v-model="previewType"> Tab
                </label>
                <label :class="'btn btn-secondary ' + (previewType == 'desktop' ? 'active' : '')">
                    <img src="../assets/images/icon-desktop.svg" alt="">
                    <input type="radio" name="options" value="desktop" autocomplete="off" v-model="previewType"> Desktop
                </label>


            </div>
            <!--            <div class="toning-setting__link">-->
            <!--                <p class="toning-setting__link-title">Related Setting</p>-->
            <!--                <a href="#">Editor</a>-->
            <!--                <a href="#" v-b-modal.helpModal>Get help</a>-->
            <!--            </div>-->

        </div>

        <div class="phone-demo-wrap" v-if="previewType == 'mobile'">

            <div class="toning-setting__phone-demo" v-for="(route, key) in previewRoutes" :key="key">
                <iframe @load="iframeReady"
                        :src="route + '&configHost=' + encodedApiUrl + '&device=phone&v=' + (new Date()).getTime()"
                        height="100%"
                        width="100%"
                        ref="iframe"></iframe>
            </div>
        </div>

        <div class="tab-demo-wrap" v-if="previewType == 'tab'">

            <div class="toning-setting__tab-demo" v-for="(route,key) in previewRoutes" :key="key">
                <iframe @load="iframeReady"
                        :src="route + '&configHost=' + encodedApiUrl + '&device=tablet&v=' + (new Date()).getTime()"
                        height="100%"
                        width="100%"
                        ref="iframe"></iframe>
            </div>
        </div>

        <div class="desktop-demo-wrap" v-if="previewType == 'desktop'">

            <div class="toning-setting__desktop-demo" v-for="(route,key) in previewRoutes" :key="key">
                <iframe @load="iframeReady"
                        :src="route + '&configHost=' + encodedApiUrl + '&device=desktop&v=' + (new Date()).getTime()"
                        height="100%"
                        width="100%"
                        ref="iframe"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import TTInstanceService from '../services/TTInstanceService'

export default {
  name: 'AppPreview',
  data () {
    return {
      component: {},
      previewType: 'mobile',
      previewHost: process.env.VUE_APP_PREVIEW_URL,
      encodedApiUrl: encodeURIComponent(process.env.VUE_APP_API_HOST),
      previewRoutes: [],
      defaultRoute: '/login',
      queryParams: '',
      instanceService: new TTInstanceService()
    }
  },
  mounted () {
    // this.$store.commit('setLoading', true)
  },
  computed: {
    trainerId () {
      return this.$store.getters.getTrainer
    },
    template () {
      return this.$store.getters.getTemplate
    },
    componentId () {
      return this.$store.getters.getComponent
    },
    instance () {
      return this.$store.getters.getInstance
    },
    refreshNeeded () {
      return this.$store.getters.getPreviewRefreshRequired
    },
    cssInjectNeeded () {
      return this.$store.getters.getPreviewCssInjectRequired
    }
  },
  watch: {
    trainerId: function () {
      this.syncPageData()
    },
    template: {
      handler: function () {
        this.syncPageData()
      },
      deep: true
    },
    componentId: function () {
      this.syncPageData()
    },
    refreshNeeded: function (val) {
      this.$store.commit('setPreviewRefreshRequired', false)
      if (val) { this.refreshPreview() }
    },
    cssInjectNeeded: function () {
      let elements = this.$refs.iframe
      for (let i = 0; i < elements.length; i++) {
        this.injectCssSubmit(this.$refs.iframe[i])
      }

      this.$store.commit('setPreviewCssInjectRequired', false)
    }
  },
  methods: {
    refreshPreview () {
      let elements = this.$refs.iframe
      if (elements) {
        for (let i = 0; i < elements.length; i++) {
          this.$refs.iframe[i].src += ''
        }
      }
    },
    injectCssSubmit (element) {
      this.$store.commit('setLoading', true)
      this.instanceService.getRawCss(this.$store.getters.getInstance.id)
        .then(response => {
          let rawCss = response.data.raw_css
          element.contentWindow.postMessage({'styles': rawCss}, '*')
        })
        .finally(() => {
          this.$store.commit('setLoading', false)
        })
    },
    iframeReady: function () {
      this.$store.commit('setLoading', false)
    },
    syncPageData () {
      if (this.trainerId && this.template.id) {
        this.queryParams = 'trainerId=' + this.trainerId + '&templateId=' + this.template.id
        if (this.componentId) {
          this.setComponent()
        } else {
          this.component = {}
        }
        this.setPreviewRoutes()
      } else {
        this.previewRoutes = []
      }
    },
    setPreviewRoutes () {
      if(
        !this.component ||
        (this.component && Object.keys(this.component).length === 0)
      ) {
        // no component selected (load default preview)
        this.previewRoutes = []
        this.previewRoutes.push(this.previewHost + this.defaultRoute + '?' + this.queryParams)

      } else if ('path' in this.component && this.component.path && this.component.path.length > 0) {
        // component is selected and has preview paths
        this.previewRoutes = []
        let pathList = this.component.path
        let pathListLength = pathList.length

        if (pathListLength > 0) {
          for (let i = 0; i < pathListLength; i++) {
            let qPChar = '?'
            if (pathList[i].includes('?')) {
              qPChar = '&'
            }

            this.previewRoutes.push(this.previewHost + pathList[i] + qPChar + this.queryParams)
          }
        }
      } else {
        // component is selected but no preview paths
        this.previewRoutes = []
      }
    },
    setComponent () {
      this.component = this.$store.getters['components/getById'](this.componentId)
    }
  }
}
</script>

<style scoped>

</style>
